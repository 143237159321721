import React, { useState, useEffect } from 'react'
import { Collapse, Tooltip } from 'reactstrap'
import Header from '../header/Header'
import Footer from '../footer/Footer';
import DownloadSearchCriteria from '../downloadSearchCriteria/DownloadSearchCriteria'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faCopy, faDownload } from '@fortawesome/free-solid-svg-icons'
import backend from '../../services/backend'
import AlertMessage from '../alertMessage/AlertMessage'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import AlertModal from '../Modals/alertModal/AlertModal';
import moment from 'moment'
import utils from '../../services/utils'
import constants from '../../constants'
import './download.css'
import { DataGrid, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid';
import { Box, Grid } from '@mui/material';
import TableToolbar from  '../../components/table-toolbar/TableToolbar';
import {
    handleTableFilterChange, handleTableColumnVisibilityChange,
    handleTablePaginationChange, handleTableSortingChange, mapDownloads,
} from '../../components/utilities/table-utilities';

const Download = (props) => {
    const [clients, setClients] = useState(undefined)
    const [actions, setActions] = useState(undefined)
    const [users, setUsers] = useState(undefined)
    const [searchAlertVisible, setSearchAlertVisible] = useState(false)
    const [searchAlertMessage, setSearchAlertMessage] = useState()
    const [downloads, setDownloads] = useState([])
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [error, setError] = useState()
    const [waitingHeader, setWaitingHeader] = useState()
    const [role, setRole] = useState()
    const recordingStatus = {
        value:"Available",
        value:"Expired",
        value:"In Progress"
    }

    
    // Tooltips
    const [downloadTooltipOpen, setDownloadTooltipOpen] = useState(false)
    const gridRef = useGridApiRef();
    const tablePrefId = 'downloads';

    useEffect(() => { // initial load
        const fetchData = async () => {
            setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
            try {
                setWaitingHeader('Loading Downloads');
                setIsWaitingModalOpen(true);
            } catch (error) {
                setError(`An error occured while fetching users:${JSON.stringify(error.message)}`)
                console.log(error)
            } finally {
                setIsWaitingModalOpen(false)
            }
        }
        fetchData()
    }, [])

    const fetchDownloads = async (searchCriteria) => {
        try {
            setWaitingHeader('Loading Downloads')
            setIsWaitingModalOpen(true)
            const response = await backend.exportStatus(sessionStorage.getItem(constants.GC_TOKEN), searchCriteria)
            // console.log('fetchData.response:', response)
            if (response.records_returned < response.total_records) {
                setSearchAlertVisible(true)
                setSearchAlertMessage(`Showing ${response.records_returned} of ${response.total_records} records. To find specific set of results, please refine your search criteria.`)
            }
            setDownloads(mapDownloads(response.exportStatus))
            console.log(`Export Status Response is ${JSON.stringify(response.exportStatus)}`);
            sessionStorage.setItem(constants.SESSION_KEY_EXPORT_STATUS, JSON.stringify(response.exportStatus))

        } catch (error) {
            setError(`An error occured while fetching downloads report:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }
    }

    const search = async (searchCriteria) => {
        console.log(`AccessReport.searchCriteria: ${JSON.stringify(searchCriteria)}`);
        fetchDownloads(searchCriteria)
        console.log(JSON.stringify(downloads));
    }

     

    const DownloadReport = async () => {
        utils.JSONToCSVConvertor(downloads, `R2S_AccessReport_${moment().format()}`, true)
    }

    const getDate = (date) => {
        //return (moment.utc(date).format("MMM Do YYYY, h:mm:ss a"));
        return date ? new Date(date).toLocaleString() : "";
    }

    const defaultWidthColumns = 150;
    const columns = [
        {
            field: 'submitteddate',
            headerName: 'Submitted Date',
            type: 'string',
            filterable: false,
            width: defaultWidthColumns + 50,
            valueGetter: (params) => getDate(params.row.submitteddate)
        },
        {
            headerName: 'Submitted By',
            field: 'email',
            width: defaultWidthColumns + 100,           
        },
        {
            headerName: 'Export Criteria',
            field: 'criteria',
            width: defaultWidthColumns + 100             
        },
        {
            field: 'expiryTimeStamp',
            headerName: 'Expires at',
            type: 'string',
            filterable: false,
            width: defaultWidthColumns + 50,
            valueGetter: (params) => getDate(params.row.expiryTimeStamp)
           
        },
        {
            headerName: 'Status',
            field: 'jobstatus',
            width: defaultWidthColumns + 100             
        },
        {
            headerName: 'Download Link',
            field: 'link',
            filterable: false,
            width: defaultWidthColumns + 50,
            renderCell: (params) => {
                return (
                  <React.Fragment>
                     {params.row.link && (
                    <a href={params.row.link} target="_blank" rel="noopener noreferrer" >Download</a>   
                    )}
                  </React.Fragment>
                )
            }
        }
        
    ]
    return (
        <React.Fragment>
            <Header role={role} />
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingHeader}
            />
            
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <AlertMessage
                message={searchAlertMessage}
                visible={searchAlertVisible}
                setVisible={x => { setSearchAlertVisible(x) }}
            />
            <Box sx={{my:8}}>              
                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, md: 2 }}>
                    <Grid item xs={12} md={12}>
                        <DownloadSearchCriteria
                            search={search}
                        />
                    </Grid>
           
                    <Grid item xs={12} md={12}>
                        <DataGrid
                        components={{
                            Toolbar: TableToolbar,
                        }}
                        sx={{
                            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                              {
                                "margin-top": "1em",
                                "margin-bottom": "1em",
                              },
                        }}
                        apiRef={gridRef}
                        key={tablePrefId}
                        autoHeight
                        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                        defaultPageSize={15}
                        rows={downloads}
                        columns={columns}
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        filterModel={{
                            items: []
                        }}           
                        sortingOrder={['desc', 'asc']}         
                        />
                    </Grid>
                </Grid>
            </Box>
             {/*<ReactTable
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={15}
                minRows={0}
                filterable
                className="-highlight -striped table-container"
                data={audit}
                columns={[{
                    Header: 'Submitted Date',
                    accessor: 'submitteddate',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {moment(row.value).format("MMM Do YYYY, h:mm:ss a Z")}
                            </div>
                        )
                    },
                    filterable: false
                },
                {
                    Header: 'Submitted By',
                    accessor: 'email',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Export Criteria',
                    accessor: 'criteria',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {JSON.stringify(row.value)}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Expires at',
                    accessor: 'expiryTimeStamp',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {moment(row.value).format("MMM Do YYYY, h:mm:ss a Z")}
                            </div>
                        )
                    },
                    filterable: false
                },
                {
                    Header: 'Status',
                    accessor: 'jobstatus',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Download Link',
                    accessor: 'link',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="div-center overflow">
                                <a href={row.original.link} target="_blank" rel="noopener noreferrer" >Download</a>   
                            </div>
                        )
                    },
                    filterable: false
                }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            cursor: 'pointer'
                        }
                    }
                }}
                getTrProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                getTrGroupProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                defaultFilterMethod={(filter, row) => {
                    const id = filter.pivotId || filter.id;
                    return (row[id] !== undefined ?
                        String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                        : true
                    );
                }}
            />*/}          
            <Footer />
        </React.Fragment>       
    ) 
}



export default Download