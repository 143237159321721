import constants from './constants'

// private config
const development = {
    endpoints: {
        backend: 'https://vanguard.dev.api.r2s.genesysinnoapps.net',
    },
    clientID: constants.GC_CLIENT_ID,
    integrationType: constants.R2S_INTEGRATION_TYPE,
    appName: "Genesys Recording as a Service (R2S) - Dev",
    defaultEnvironment: constants.GC_ENV_US_EAST_1,
    defaultLangTag: constants.GC_LANG_TAG,
    app: {
        storageVersion: 'v1',
        solutionKey: 'r2s-app',
        solutionName: 'r2sS'
    }
}

const production = {
    endpoints: {
        backend: 'https://vanguard.api.r2s.genesysinnosecureapps.com',
    },
    clientID: constants.GC_CLIENT_ID,
    integrationType: constants.R2S_INTEGRATION_TYPE,
    appName: "Genesys Recording as a Service (R2S)",
    defaultEnvironment: constants.GC_ENV_US_EAST_1,
    defaultLangTag: constants.GC_LANG_TAG,
    app: {
        storageVersion: 'v1',
        solutionKey: 'r2s-app',
        solutionName: 'r2sS'
    }
}

const qa = {
    endpoints: {
        backend: 'https://vanguard.demo.api.r2s.genesysinnoapps.net',
    },
    clientID: constants.GC_CLIENT_ID,
    integrationType: constants.R2S_INTEGRATION_TYPE, 
    appName: "Genesys Recording as a Service (R2S) QA",
    defaultEnvironment: constants.GC_ENV_US_EAST_1,
    defaultLangTag: constants.GC_LANG_TAG,
    app: {
        storageVersion: 'v1',
        solutionKey: 'r2s-app',
        solutionName: 'r2sS'
    }
}

const env = process.env.REACT_APP_CUSTOM_ENV.trim()
console.log('env:', env)
let config
switch (env) {
    case constants.REACT_ENV_PROD: config = production; break;
    case constants.REACT_ENV_DEV: config = development; break;
    case constants.REACT_ENV_TEST: config = test; break;
    default: break;
}

export default config
