import React, { Fragment, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Typography, Box, Grid, TextField, Autocomplete } from "@mui/material";

const CSelectMultipleField = ({
  title,
  id,
  formFields,
  fieldName,
  fieldOptions,
  handler,
  disableHandler = () => {},
  disabledInput = false,
  freeEntry = false,
}) => {
  // If fieldOptions has only one item, use that as the value and disable the input
  const isSingleOption = fieldOptions.length === 1;
  const isEmptyOption = fieldOptions.length === 0;

  const isUserRole = sessionStorage.getItem("r2s-user-role") === "user";
  const isBusinessUnitField = fieldName === "bussinessUnit";

  let value = isSingleOption
    ? [fieldOptions[0]]
    : formFields[`${fieldName}Value`] || [];

  const shouldBeDisabled =
    disabledInput || isSingleOption || isEmptyOption || (isBusinessUnitField && isUserRole);

  const customFilter = (options, { inputValue }) => {
    return options
      .filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
      .slice(0, 100); // Limit to first 100 matches
  };

  return (
    <Fragment>
      <Grid item xs={12} md={12}>
        <Autocomplete
          multiple={true}
          filterSelectedOptions={true}
          id={id}
          label={title}
          disabled={shouldBeDisabled} // Disable if input is meant to be disabled or if there's only one option
          fullWidth
          freeSolo={freeEntry}
          size="small"
          options={fieldOptions}
          autoHighlight
          value={value}
          onChange={handler}
          filterOptions={customFilter}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) =>
            option &&
            value &&
            (option.label === value.label || option.value === value.value)
          }
          renderOption={(props, option) => {
            const key = option.id || uuidv4();
            return (
              <Box component="li" {...props} key={key}>
                {option.label}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
              }}
              variant="standard"
              fullWidth
            />
          )}
          getOptionDisabled={disableHandler}
        />
        <Typography
          component="p"
          variant="caption"
          sx={{ mb: 0, ml: 1.7, mt: 0.5 }}
        >
          {formFields[`${fieldName}Error`]
            ? formFields[`${fieldName}ErrorMsg`]
            : formFields[`${fieldName}HelpText`]}
        </Typography>
      </Grid>
    </Fragment>
  );
};

export default CSelectMultipleField;
