import React from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import Footer from "../footer/Footer";
import { Container } from "reactstrap";
import Header from "../header/Header";

const Unauthorized = () => {
  const location = useLocation(); 
  const message = location.state?.message || "You're not authorized to access the requested resource. Please check with your system administrator.";

  return (
    <div id="wrap" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <div id="main" style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Container style={{textAlign:'center'}}>
                <p>{message}</p>
            </Container>
        </div>
        <Footer />
    </div>
  );
};

export default Unauthorized;
