import React from "react";

import "./Header.css";
// Third Party
import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  Button,
  Box,
  Tooltip,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
} from "@mui/material";
import icon from "../../images/icon.png";
const handleSettings = (event) => {
  setAnchorEl(event.currentTarget);
};
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import constants from "../../constants";


const logout = () => {};
const Header = (props) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchView = () => {
    history.push("/management/searchview");
  };

  const role = props.role? props.role : sessionStorage.getItem(constants.R2S_USER_ROLE)

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar variant="dense">
          <Box
            component="img"
            sx={{ height: 25, width: 25 }}
            alt="E3S"
            src={icon}
          />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, color: "white" }}
          >
            <Link
              component={RouterLink}
              to="/conversations"
              underline="none"
              color="inherit"
            >
              Recording As A Service
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <div>
            {role && role.toLowerCase() === "admin" && (
              <>
                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/audits/access/"
                >
                  Audit Report
                </Button>
                <Button
                  color="inherit"
                  id="basic-button"
                  aria-controls={open ? "config-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  Configuration
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleSearchView}>
                    {/* <ListItemIcon>
            <ViewListIcon fontSize="small" />
          </ListItemIcon> */}
                    <Typography variant="inherit">
                      Result View-Verint
                    </Typography>
                  </MenuItem>
                </Menu>
                {/* <Button color="inherit" component={RouterLink} to="/conversations">
                  Conversations
                </Button> */}
                <Button color="inherit" component={RouterLink} to="/downloads">
                  Downloads
                </Button>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
