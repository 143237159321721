import React, {useEffect, useState } from 'react'
import { Container,Button,Row, Col } from 'reactstrap'
import {useHistory} from 'react-router-dom'
import DualListBox from 'react-dual-listbox'
import Header from '../header/Header'
import Footer from '../footer/Footer';
import { useToasts } from '../toastManager/ToastManager'
import 'react-dual-listbox/lib/react-dual-listbox.css';
import 'font-awesome/css/font-awesome.min.css'
import backend from '../../services/backend'
import './conversationAttributes.css'
import AlertModal from '../Modals/alertModal/AlertModal'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import ConfirmationModal from '../Modals/confirmationModal/ConfirmationModal'
import constants from '../../constants'


const ConversationAttributes = (props) => {
     const history = useHistory()
     const { add } = useToasts()
    const [availableColumns, setAvailableColumns] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState();
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [waitingHeader, setWaitingHeader] = useState(false)
    const [error, setError] = useState()
    const [message, setMessage] = useState()
    const [role, setRole] = useState()

    const getAvailableAttributes = async() => {
        try {
        setIsWaitingModalOpen(true)
        setWaitingHeader("Retrieving the column attributes")
        const availableReponse = await backend.getConversationsSettings(sessionStorage.getItem(constants.GC_TOKEN))
        const allColumns = availableReponse.conversationSettings.conversationColumns
        setAvailableColumns(allColumns)
        setVisibleColumns(allColumns.map( x => x.visible ? x.value : '').filter( x => x != ""))
        sessionStorage.setItem(constants.SESSION_KEY_AVAILABLE_ATTRIBUTES, JSON.stringify(allColumns))
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }
    }

    const putVisibleAttributes = async(orderedColumns) => {
        try {
            setIsWaitingModalOpen(true)
            setWaitingHeader("Updating the column attributes")
            const visibleResponse = await backend.updateConversationsSettings(sessionStorage.getItem(constants.GC_TOKEN),orderedColumns)
            setIsWaitingModalOpen(false)
            setMessage(`Attributes have been updated successfully`)
            const timeout = setTimeout(() => {
                history.push("/conversations");
              }, 2000);
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }
    }


    const getOrderedArray = () => {
        const orderedColumn = []
        const temporaryColumn = availableColumns
        const visibleCol = visibleColumns
        for (let i=0 ; i < visibleCol.length ; i++)
            {
                const moveIndex = temporaryColumn.findIndex(obj => obj.value == visibleCol[i])
                orderedColumn.push(temporaryColumn[moveIndex])
                temporaryColumn.splice(moveIndex, 1)
            };
        return orderedColumn.concat(temporaryColumn)
    }

    useEffect(() => {
        setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
        getAvailableAttributes()
    },[])


    return (
        <div id="component-wrap">
            <Header role={role}/>
            <div id="main" style={{marginTop: 30}}>
                    <WaitingModal
                    isOpen={isWaitingModalOpen}
                    header = {waitingHeader}
                     />

                    <ConfirmationModal
                    isOpen={!!message}
                    header='Success!'
                    toggle={() => { setMessage(undefined) }}
                    body={message}
                    />  

                    <AlertModal
                    isOpen={!!error}
                    header='Error'
                    toggle={() => { setError(undefined) }}
                    body={error}
                    />
                <br />
                <label className = "title-name">Result View</label>
                <hr className = "hori-line"/>
                
             <Row className = "label-name">
                <Col>
                <label >
                    Available
                </label>
                </Col>

                <Col>
                <label > 
                    Visible
                </label> 
                </Col>
             </Row>           

                <DualListBox className="list-box"
                    icons={{
                            moveLeft: <span className="fa fa-angle-left" />,
                            moveAllLeft: <span className="fa fa-angle-double-left" />,
                            moveRight: <span className="fa fa-angle-right" />,
                            moveAllRight: <span className="fa fa-angle-double-right" />,
                            moveDown: <span className="fa fa-angle-down" />,
                            moveUp: <span className="fa fa-angle-up" />,
                            moveTop: <span className ="fa fa-angle-double-up" />,
                            moveBottom: <span className ="fa fa-angle-double-down" />,
                        }}
                    preserveSelectOrder
                    showOrderButtons
                    options={availableColumns}
                    selected={visibleColumns}
                    onChange={e => setVisibleColumns(e)}
                />
           <br />           
      <div className='row justify-content-center d-flex flex-row-reverse bd-highlight'>
                <div className='col col-2 '>
                    <button type="button" className="btn btn-primary btn-space" onClick={() => {
                    availableColumns.forEach((item) => visibleColumns.indexOf(item.value) > -1 ? item.visible = true : item.visible = false)
                    const orderedColumns = getOrderedArray(availableColumns,visibleColumns)
                    putVisibleAttributes(orderedColumns)
                   // console.log(availableColumns,visibleColumns)
                }}>Save</button>
                    <button type="button" className="btn btn-secondary btn-space" onClick={() => {
                   // console.log("cancelling...")
                    history.push("/conversations")
                }}>Cancel</button>
                </div>
            </div>

      </div>
            <Footer version="0.1" />
        </div>
    )
}


export default ConversationAttributes