import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Grid } from "@mui/material";

const ConversationDetailsDataGrid = (props) => {

  const {data} = props;

  const columns = [
    {
      field: "key",
      headerName: "Field",
      flex: 0.25,
      headerClassName: "datagrid-header",
    },
    { field: "value", headerName: "Value", flex: 1 },
  ];

  const rows = Object.entries(data).map(([key, value], index) => ({
    id: index,
    key,
    value,
  }));

  return (
    <Box sx={{ minHeight: 300, maxHeight: 600, width: "100%", my: 2 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={rows.length}
        hideFooterPagination
        hideFooterSelectedRowCount
        autoHeight
        sx={{
          "& .MuiDataGrid-row": {
            minHeight: 25 ,
          },
          "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .datagrid-header": {
            fontWeight: "strong",
          },
          ".MuiDataGrid-cell": {
            whiteSpace: "normal",
            overflow: "hidden",
            lineHeight: "1",
            maxHeight: "none",
            wordBreak: "break-word",
          },
          ".MuiDataGrid-cell:hover": {
            overflow: "visible",
          },
        }}
      />
    </Box>
  );
};

export default ConversationDetailsDataGrid;
