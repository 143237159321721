import React, { useState } from 'react';
import './AccessReportSearchCriteria.css';
import moment from 'moment';
import { Button, TextField, Grid, InputAdornment, Typography, Box } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SearchIcon from "@mui/icons-material/Search";
import CDateField from '../common/CDateField';

const AccessReportSearchCriteria = (props) => {
    const [startSearch, setStartSearch] = useState(false);
    const [fields, setFields] = useState({ start: "", end: "" })
    const [searchDisabled, setSearchDisabled] = useState(true);
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date
    
    const search = async () => {
        setStartSearch(true);
        if (!fields.start || !fields.end) return;
        console.log('searching...');
        let searchCriteria = {};
        if (fields.start) {
            searchCriteria.startDate = fields.start.startOf('day').toISOString();
        }
        if (fields.end) {
            searchCriteria.endDate = fields.end.endOf('day').toISOString();
        }
        console.log(`DownloadSearchCriteria.js Search parameters are ${JSON.stringify(searchCriteria)}`);
        props.search(searchCriteria);
    }

    const handleChangeStartDate = (passedMoment) => {

        if (passedMoment && passedMoment.isValid()) {
            if (fields.start && typeof (fields.start) !== 'string') {
                passedMoment.hour(fields.start.hour());
                passedMoment.minute(fields.start.minute());
            }

            // also set end time date
            let endMoment = moment(passedMoment);
            if (fields.end && typeof (fields.end) !== 'string') {
                endMoment.hour(fields.end.hour());
                endMoment.minute(fields.end.minute());
                if (endMoment.hour() < passedMoment.hour() || (endMoment.hour() == passedMoment.hour() && endMoment.minute < passedMoment.minute())) {
                    endMoment.add(1, 'days');
                }
            }

            setFields({
                ...fields,
                start: passedMoment,
                end: endMoment,
            });
            setSearchDisabled(false);
            setdateRange([passedMoment.format("YYYY-MM-DD"), endMoment.format("YYYY-MM-DD")]);
        } else {
            setSearchDisabled(true);
        }
        return;
    };
    const handleChangeEndDate = (passedMoment) => {

        if (passedMoment && passedMoment.isValid()) {
            /* if (fields.end && typeof (fields.end) !== 'string') {
                 passedMoment.month(fields.end.month());
                 passedMoment.date(fields.end.date());
                 passedMoment.year(fields.end.year());
             }*/

            let startMoment = fields.start;
            if (passedMoment.hour() < startMoment.hour() || (passedMoment.hour() == startMoment.hour() && passedMoment.minute < startMoment.minute())) {
                // bump up end date if need be
                passedMoment.date(startMoment.date()).add(1, 'day');
            }

            setFields({
                ...fields,
                end: passedMoment,
            });
            setSearchDisabled(false);
            setdateRange([startMoment.format("YYYY-MM-DD"), passedMoment.format("YYYY-MM-DD")]);
        } else {
            setSearchDisabled(true);
        }
        return;
    };
    return (
        <React.Fragment>
            <Box component="form" noValidate autoComplete="off" onSubmit={search} sx={{ padding: '16px', backgroundColor: '#f5f5f5' }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6} md={3}>
                        {/*<LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={setStartDate}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>*/}
                        <Typography component="label" htmlFor="start-date" variant='subtitle2' color="textSecondary" sx={{ display: 'block', marginBottom: '8px' }}>
                            Start Date
                        </Typography>
                        <CDateField
                            formFields={fields} fieldName={'start'}
                            handler={handleChangeStartDate}
                            id="start-date"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        {/*<LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={setEndDate}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>*/}
                        <Typography component="label" htmlFor="start-date" variant='subtitle2' color="textSecondary" sx={{ display: 'block', marginBottom: '8px' }}>
                            End Date
                        </Typography>
                        <CDateField
                            formFields={fields} fieldName={'end'}
                            handler={handleChangeEndDate}
                            id="start-date"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button size="medium" variant="contained" color='primary' disabled={searchDisabled} onClick={search} startIcon={<SearchIcon />}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}

export default AccessReportSearchCriteria;
