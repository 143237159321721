import React, { useState, useEffect } from "react";
import "./Footer.css";
import { Typography } from "@mui/material";
import { pureCloudService } from "../../services/purecloud";
import {
  GC_ENVIRONMENT,
  GC_TOKEN,
  R2S_WEBAPP_VERSION,
  R2S_USER_ROLE,
} from "../../constants";

const Footer = (props) => {
  const [username, setUsername] = useState("");
  const [orgName, setOrgName] = useState("");
  const [version, setVersion] = useState("");

  const role = props.role ? props.role : sessionStorage.getItem(R2S_USER_ROLE);

  useEffect(() => {
    async function fetchUserData() {
      const loggedInUser = await pureCloudService.getCurrentUserDetails(
        sessionStorage.getItem(GC_ENVIRONMENT),
        sessionStorage.getItem(GC_TOKEN)
      );
      const userOrg = await pureCloudService.getOrganization(
        sessionStorage.getItem(GC_ENVIRONMENT),
        sessionStorage.getItem(GC_TOKEN)
      );

      setUsername(loggedInUser.name);
      setOrgName(userOrg.name);
      setVersion(R2S_WEBAPP_VERSION);
    }

    fetchUserData();
  }, []);

  return (
    <footer
      className="footer"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 10px",
      }}
    >
      <Typography
        noWrap
        component="div"
        sx={{
          flexGrow: 1,
          color: "white",
          textAlign: "left",
          fontSize: "14px",
        }}
      >
        {role && (
          <>
            {`${
              role.toLowerCase() === "supervisorwithdownload"
                ? "Supervisor+"
                : role.toLowerCase() === "ogc_admin"
                ? "OGC_Admin"
                : role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()
            }`}
            : {username} || Organization: {orgName}
          </>
        )}
      </Typography>
      <Typography
        noWrap
        component="div"
        sx={{
          flexGrow: 1,
          color: "white",
          textAlign: "right",
          fontSize: "14px",
        }}
      >
        R2S Version: {version}
      </Typography>
    </footer>
  );
};

export default Footer;
