import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarContainer, GridCsvExportMenuItem, GridToolbarExport } from "@mui/x-data-grid"
import { Grid, Button, IconButton, Typography } from '@mui/material';
import { CloudDownload } from '@mui/icons-material'

const TableToolbar = (props) => {
    const { downloadAction, disableDownload, showDownload } = props
    const handleExport = () => {
        downloadAction();
    };
    const buttonBaseProps = {
        color: "primary",
        size: "small",
        startIcon: <CloudDownload />,
    };
    return (
        <GridToolbarContainer sx={{ bgcolor: 'white', border: '1px solid grey' }}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            {<Grid>
                {showDownload && (
                <Button {...buttonBaseProps} onClick={() => handleExport()} disabled={disableDownload}>
                    Recordings
                </Button>)}
            </Grid>}
        </GridToolbarContainer>
    )
};

export default TableToolbar;