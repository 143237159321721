import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import config from "../config";
import { pureCloudService } from "../services/purecloud";
import constants from "../constants";
import backendAPI from "../services/backend";
import WaitingModal from "./Modals/waitingModal/WaitingModal";

const Main = () => {
  const history = useHistory();
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
  const [waitingHeader, setWaitingHeader] = useState();

  // Opens authentication popup and listens for messages
  const openAuthPopup = (environment) => {
    const authUrl = `/auth?environment=${environment}`;
    const windowFeatures =
      "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=480,height=570,left=100,top=100";
    window.open(authUrl, "_blank", windowFeatures);

    const receiveMessage = (event) => {
      let data = event.data;
      if (data.type === "genesyscloud-auth") {
        let authData = JSON.parse(data.data);
        // sessionStorage.clear();
        sessionStorage.setItem(constants.GC_TOKEN, authData.accessToken);
        handleUserValidation();
      }
    };
    window.addEventListener("message", receiveMessage, false);
  };

  // Handles user validation
  const handleUserValidation = async () => {
    const token = sessionStorage.getItem(constants.GC_TOKEN);
    if (!token) {
      history.push("/unauthorized");
      return;
    }
    try {
      setWaitingHeader("User authenticating");
      setIsWaitingModalOpen(true);
      const usersMe = await backendAPI.getUsersMe(
        sessionStorage.getItem(constants.GC_TOKEN)
      );
      const isUser = usersMe.user.role.value;

      console.log("isUser : ", isUser);
      let redirectUrl =
        sessionStorage.getItem("redirect_to") ?? "/conversations";
      history.push(isUser ? redirectUrl : "/unauthorized");
      //history.push(isUser ? "/conversations" : "/unauthorized");
    } catch (error) {
      console.error(`Error during authentication: ${error.message}`);
      if (
        error &&
        error.message &&
        error.message === "Your search returned no recordings for this user."
      ) {
        history.push({
          pathname: "/unauthorized",
          state: { message: error.message },
        });
      } else {
        history.push("/unauthorized");
      }
    }
  };

  // Initializes component
  useEffect(() => {
    const init = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const environment =
        urlParams.get("environment") || config.defaultEnvironment;
      sessionStorage.setItem(constants.GC_ENVIRONMENT, environment);
      openAuthPopup(environment);
    };
    init();
  }, [history]);

  return (
    <Fragment>
      <WaitingModal isOpen={isWaitingModalOpen} header={waitingHeader} />
    </Fragment>
  );
};

export default Main;
