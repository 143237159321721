import React from 'react';
import { Modal, ModalHeader, ModalBody,ModalFooter } from 'reactstrap'
import { BeatLoader } from 'react-spinners'
import './WaitingModal.css'

const WaitingModal = (props) => {
    const { isOpen, header = 'Loading', onCancel } = props;

    return (
        <Modal className="waiting-modal--centered animated fadeIn" isOpen={isOpen}>
            <ModalHeader className="waiting-modal--centered">{header}</ModalHeader>
            <ModalBody className="waiting-modal--centered">
                <div className="waiting-modal__spinner">
                    <BeatLoader color="currentColor" />
                </div>
            </ModalBody>
            <ModalFooter>
                {onCancel && (
                    <button className="btn btn-secondary" onClick={onCancel}>
                        Cancel
                    </button>
                )}
            </ModalFooter>
        </Modal>
    );
}


export default WaitingModal;
