import React, { useEffect } from "react";
import constants from "../../../src/constants";

const GCAuthPopup = () => {
  useEffect(() => {
    const parseHash = () => {
      const params = new URLSearchParams(window.location.hash.substring(1));
      if (params.get("error")) {
        return { error: params.get("error") };
      }
      if (params.get("access_token")) {
        const expiresInMs = parseInt(params.get("expires_in")) * 1000;
        return {
          state: params.get("state"),
          accessToken: params.get("access_token"),
          tokenExpiryTime: Date.now() + expiresInMs,
          tokenExpiryTimeString: new Date(
            Date.now() + expiresInMs
          ).toISOString(),
        };
      }
      return {};
    };

    try {
      const params = new URLSearchParams(window.location.hash.substring(1));
      const authData = parseHash();
      const clientId = constants.GC_CLIENT_ID;
      const queryParams = new URLSearchParams(window.location.search);
      const env = queryParams.get('environment') ? queryParams.get('environment') : "";
      const redirectUri = window.location.origin + "/auth";

      if (!authData.accessToken && env) {
        window.location.href = `https://login.${env}/oauth/authorize?client_id=${clientId}&response_type=token&redirect_uri=${redirectUri}`;
      } else if (authData.accessToken) {
        const data = JSON.stringify(authData);
        window.opener.postMessage(
          { data, type: "genesyscloud-auth" },
          window.location.origin
        );
        window.close();
      }
    } catch (error) {
      console.error("Error in AuthPopup:", error);
    }
  }, []);

  return <div>Loading authentication...</div>;
};

export default GCAuthPopup;
