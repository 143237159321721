import React, { useState, useEffect } from 'react'
import './DownloadSearchCriteria.css'
import moment from 'moment';
import { Button, Box, Grid, Typography } from '@mui/material';
import CDateField from '../common/CDateField';
import SearchIcon from '@mui/icons-material/Search';


const DownloadSearchCriteria = (props) => {
      
    const [startSearch, setStartSearch] = useState(false)
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date
    const [fields, setFields] = useState({ start: "", end: "" })
    const [searchDisabled, setSearchDisabled] = useState(true);
    const recodingStatus = [
        { label: "Available", value: "Available" },
        { label: "Expired", value: "Expired" },
        { label: "In Progress", value: "In Progress" }
    ]
    

    function onSelectDate(dateArray, dateString) 
    {        
        console.log(dateArray)
        setdateRange(dateString);
    }

    const search = async () => {
        setStartSearch(true)
        if (!dateRange[0] || !dateRange[1]) return
        console.log('searching...')
        let searchCriteria = {};
        if (dateRange[0]){
            let tempStartDate = moment(dateRange[0],'YYYY-MM-DD').set({"hour": 0, "minute": 0});           
            searchCriteria.startDate = tempStartDate.toISOString();
        } 
        if (dateRange[1]){
            let tempEndDate = moment(dateRange[1],'YYYY-MM-DD').set({"hour": 23, "minute": 59});
            searchCriteria.endDate = tempEndDate.toISOString();
        }
        console.log(`DownloadSearchCriteria.js Search parameters are ${JSON.stringify(searchCriteria)}`);
        props.search(searchCriteria)
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    const handleChangeStartDate = (passedMoment) => {

        if (passedMoment && passedMoment.isValid()) {
            if (fields.start && typeof (fields.start) !== 'string') {
                passedMoment.hour(fields.start.hour());
                passedMoment.minute(fields.start.minute());
            }

            // also set end time date
            let endMoment = moment(passedMoment);
            if (fields.end && typeof (fields.end) !== 'string') {
                endMoment.hour(fields.end.hour());
                endMoment.minute(fields.end.minute());
                if (endMoment.hour() < passedMoment.hour() || (endMoment.hour() == passedMoment.hour() && endMoment.minute < passedMoment.minute())) {
                    endMoment.add(1, 'day');
                }
            }

            setFields({
                ...fields,
                start: passedMoment,
                end: endMoment,
            });
            setSearchDisabled(false);
            setdateRange([passedMoment.format("YYYY-MM-DD"),endMoment.format("YYYY-MM-DD")]);
        }else{
            setSearchDisabled(true);
        }
        return;
    };
    const handleChangeEndDate = (passedMoment) => {

        if (passedMoment && passedMoment.isValid()) {
            /* if (fields.end && typeof (fields.end) !== 'string') {
                 passedMoment.month(fields.end.month());
                 passedMoment.date(fields.end.date());
                 passedMoment.year(fields.end.year());
             }*/

            let startMoment = fields.start;
            if (passedMoment.hour() < startMoment.hour() || (passedMoment.hour() == startMoment.hour() && passedMoment.minute < startMoment.minute())) {
                // bump up end date if need be
                passedMoment.date(startMoment.date()).add(1, 'day');
            }

            setFields({
                ...fields,
                end: passedMoment,
            });
            setSearchDisabled(false);
            setdateRange([startMoment.format("YYYY-MM-DD"),passedMoment.format("YYYY-MM-DD")]);
        }else{
            setSearchDisabled(true);
        }
        return;
    };
    return (

        <React.Fragment>
        <Box component="form" noValidate autoComplete="off" onSubmit={search} sx={{ padding: '16px', backgroundColor: '#f5f5f5' }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={3}>
                    <Typography component="label" htmlFor="start-date" variant='subtitle2' color="textSecondary" sx={{ display: 'block', marginBottom: '8px' }}>
                        Start Date
                    </Typography>
                    <CDateField
                        formFields={fields} fieldName={'start'}
                        handler={handleChangeStartDate}
                        id="start-date"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography component="label" htmlFor="end-date" variant='subtitle2' color="textSecondary" sx={{ display: 'block', marginBottom: '8px' }}>
                        End Date
                    </Typography>
                    <CDateField
                        formFields={fields} fieldName={'end'}
                        handler={handleChangeEndDate}
                        id="end-date"
                    />
                </Grid>
      
                <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button size="medium" variant="contained" color='primary' disabled={searchDisabled} onClick={search} startIcon={<SearchIcon />}>
                        Search
                    </Button>
                </Grid>
            </Grid>
             
        </Box>
      </React.Fragment>        
    )
}

export default DownloadSearchCriteria