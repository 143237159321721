import React, { useState, useEffect } from "react";
import { Collapse, Tooltip } from "reactstrap";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import AccessReportSearchCriteria from "../../accessReportSearchCriteria/AccessReportSearchCriteria";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faCopy,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import backend from "../../../services/backend";
import AlertMessage from "../../alertMessage/AlertMessage";
import WaitingModal from "../../Modals/waitingModal/WaitingModal";
import AlertModal from "../../Modals/alertModal/AlertModal";
import moment from "moment";
import utils from "../../../services/utils";
import constants from "../../../constants";
import "./AccessReport.css";
import { DataGrid, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import TableToolbar from "../../../components/table-toolbar/TableToolbar";
import {
  handleTableFilterChange,
  handleTableColumnVisibilityChange,
  handleTablePaginationChange,
  handleTableSortingChange,
  mapAudits,
} from "../../../components/utilities/table-utilities";
import { CopyAllRounded } from "@mui/icons-material";

const defaultInitialJobsTableState = {
  columns: {
    columnVisibilityModel: {},
  },
  sorting: {
    sortModel: [
      {
        field: "dateSequence",
        sort: "asc",
      },
    ],
  },
  filter: {
    filterModel: {
      items: [],
    },
  },
  pagination: {
    paginationModel: {
      pageSize: 25,
      page: 0,
    },
  },
};
const AccessReport = (props) => {
  const [clients, setClients] = useState(undefined);
  const [actions, setActions] = useState(undefined);
  const [users, setUsers] = useState(undefined);
  const [searchAlertVisible, setSearchAlertVisible] = useState(false);
  const [searchAlertMessage, setSearchAlertMessage] = useState();
  const [audit, setAudit] = useState([]);
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
  const [error, setError] = useState();
  const [waitingHeader, setWaitingHeader] = useState();
  const [role, setRole] = useState();

  // Collapsable
  const [isSearchCriteriaOpen, setIsSearchCriteriaOpen] = useState(true);

  // Tooltips
  const [searchAccessReportTooltipOpen, setSearchAccessReportTooltipOpen] =
    useState(false);
  const [downloadTooltipOpen, setDownloadTooltipOpen] = useState(false);

  const [tablePreferences, setTablePreferences] = React.useState(
    defaultInitialJobsTableState
  );
  const isMounted = React.useRef(true);
  const gridRef = useGridApiRef();
  const tablePrefId = "audit";

  const fetchAudit = async (searchCriteria) => {
    try {
      setWaitingHeader("Loading audit");
      setIsWaitingModalOpen(true);
      const response = await backend.getAuditAccessReport(
        sessionStorage.getItem(constants.GC_TOKEN),
        searchCriteria
      );
      // console.log('fetchData.response:', response)
      if (response.records_returned < response.total_records) {
        setSearchAlertVisible(true);
        setSearchAlertMessage(
          `Showing ${response.records_returned} of ${response.total_records} records. To find specific set of results, please refine your search criteria.`
        );
      }
      setAudit(mapAudits(response.audit));
      // console.log(`Audit Search Response are ${JSON.stringify(response.audit)}`);
      sessionStorage.setItem(
        constants.SESSION_KEY_AUDIT,
        JSON.stringify(response.audit)
      );
    } catch (error) {
      setError(
        `An error occured while fetching audit access report:${JSON.stringify(
          error.message
        )}`
      );
      console.log(error);
    } finally {
      setIsWaitingModalOpen(false);
    }
  };

  const search = async (searchCriteria) => {
    fetchAudit(searchCriteria);
    // toggleSearchCriteria(false)
    // console.log(JSON.stringify(audit));
  };

  function renderDetails(params) {
    const detailsMatch = params.value.match(/\[(.*?)\]/);
      if (!detailsMatch || !detailsMatch[1]) {
        return "No details available";
      }

      const keyValuePairs = detailsMatch[1].split(',')
        .map(pair => pair.split(':').map(part => part.trim()))
        .filter(pair => pair[0] && pair[1]);

      // Create a list of React fragments with line breaks for non-empty pairs
      const keyValueElements = keyValuePairs.map((pair, index) => (
        <React.Fragment key={index}>
          {pair[0]}: {pair[1]}<br />
        </React.Fragment>
      ));
      
      return <div style={{ whiteSpace: 'pre-line' }}>{keyValueElements}</div>;
  }

  const toggleSearchCriteria = () =>
    setIsSearchCriteriaOpen(!isSearchCriteriaOpen);

  const DownloadReport = async () => {
    utils.JSONToCSVConvertor(
      audit,
      `R2S_AccessReport_${moment().format()}`,
      true
    );
  };

    const getDate = (params) => {
        //return (moment.utc(params.row.value).format("MMM Do YYYY, h:mm:ss a"));
        return params.row.timestamp ? new Date(params.row.timestamp).toLocaleString() : "";
    }

  const getEmail = (params) => {
    return params.row.userEmail;
  };

    const defaultWidthColumns = 150;
    const columns = [
        {
            field: 'timestamp',
            headerName: 'Date',
            type: 'string',
            filterable: false,
            width: defaultWidthColumns + 50,
            valueGetter: (params) => getDate(params)
        },
        {
            headerName: 'User Email',
            field: 'userEmail',
            width: defaultWidthColumns + 100,
            valueGetter: (params) => getEmail(params)
        },
        {
            headerName: 'Action',
            field: 'action',
            width: defaultWidthColumns + 50
        },
        {
            headerName: 'Details',
            field: 'details',
            width: defaultWidthColumns + 200
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                icon={<CopyAllRounded />}
                label="Copy"
                onClick={() => {
                    navigator.clipboard.writeText(params.row.details)
                    setSearchAlertVisible(true)
                    setSearchAlertMessage(`Details copied to clipboard.`)
                }}
              />,
            ]
          }
    ]
    return (
        <div id="component-wrap">
            <Header role={role} />
            <div id="component-toolbar">
                <button id="searchAccessReport" onClick={toggleSearchCriteria}><FontAwesomeIcon className="fa-2x" icon={faFilter} /></button>
                <button id="downloadReport"
                    disabled={audit && audit.length > 0 ? false : true}
                    onClick={DownloadReport}><FontAwesomeIcon className="fa-2x" icon={faDownload} />
                </button>
            </div>
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingHeader}
            />
            <Tooltip placement="bottom" isOpen={searchAccessReportTooltipOpen}
                target="searchAccessReport"
                toggle={() => { setSearchAccessReportTooltipOpen(!searchAccessReportTooltipOpen) }}>
                Define access report criteria
            </Tooltip>
            <Tooltip placement="bottom" isOpen={downloadTooltipOpen}
                target="downloadReport"
                toggle={() => { setDownloadTooltipOpen(!downloadTooltipOpen) }}>
                Download report to CSV
            </Tooltip>
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <AlertMessage
                message={searchAlertMessage}
                visible={searchAlertVisible}
                setVisible={x => { setSearchAlertVisible(x) }}
            />
                
                    <AccessReportSearchCriteria
                        search={search}
                        cancel={() => { toggleSearchCriteria(false) }}
                    />
            <DataGrid
                components={{
                    Toolbar: TableToolbar,
                }}
                sx={{
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                      {
                        "margin-top": "1em",
                        "margin-bottom": "1em",
                      },
                }}
                apiRef={gridRef}
                key={tablePrefId}
                autoHeight={false}
                style={{ height: 400, width: '100%' }} 
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                rows={audit}
                columns={columns}
                getRowId={(row) => row.id}
                getRowHeight={() => 'auto'}
                filterModel={{
                    items: []
                }}
                sortingOrder={['desc', 'asc']}
                onFilterModelChange={(model, details) => handleTableFilterChange(model, details, tablePreferences, setTablePreferences, tablePrefId)}
                sortModel={tablePreferences.sorting.sortModel}
                onSortModelChange={(model, details) => handleTableSortingChange(model, details, tablePreferences, setTablePreferences, tablePrefId)}
                columnVisibilityModel={tablePreferences.columns.columnVisibilityModel}
                onColumnVisibilityModelChange={(model, details) => handleTableColumnVisibilityChange(model, details, tablePreferences, setTablePreferences, tablePrefId)}
                paginationModel={tablePreferences.pagination.paginationModel}
                onPaginationModelChange={(model, details) => handleTablePaginationChange(model, details, tablePreferences, setTablePreferences, tablePrefId)}
            />
            {/*<ReactTable
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={15}
                minRows={0}
                filterable
                className="-highlight -striped table-container"
                data={audit}
                columns={[{
                    Header: 'Date',
                    accessor: 'timestamp',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">                                
                                {moment.utc(row.value).format("MMM Do YYYY, h:mm:ss a")}
                            </div>
                        )
                    },
                    filterable: false
                },
                {
                    Header: 'User Email',
                    accessor: 'userEmail',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="div-center overflow">
                                <div className="overflow div-center">
                                {row.original.userEmail}
                            </div>
                                
                            </div>
                        )
                    },
                },
                {
                    Header: 'Action',
                    accessor: 'action',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Details',
                    accessor: 'details',
                    headerClassName: 'headerColumn',
                    style: { 'white-space': 'unset' } ,
                    Cell: row => {
                        return (
                            <div className="div-center">
                                <div className="div-center overflow">
                                {row.original.details}
                            </div>
                                
                            </div>
                        )
                    },
                    filterable: false
                },
                {
                    Header: 'Copy Details',
                    accessor: 'details',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="div-center overflow">
                                <button onClick={() => {
                                    navigator.clipboard.writeText(row.original.details)
                                    setSearchAlertVisible(true)
                                    setSearchAlertMessage(`Details copied to clipboard.`)
                                }}><FontAwesomeIcon icon={faCopy} /></button>
                            </div>
                        )
                    },
                    filterable: false
                }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            cursor: 'pointer'
                        }
                    }
                }}
                getTrProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                getTrGroupProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                defaultFilterMethod={(filter, row) => {
                    const id = filter.pivotId || filter.id;
                    return (row[id] !== undefined ?
                        String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                        : true
                    );
                }}
            />*/}
      <Footer />
    </div>
  );
};

export default AccessReport;
