import React, { Fragment } from 'react';
// Components Material UI
import {
    Typography, Grid, TextField
} from '@mui/material';

const CTextField = ({ title, id, formFields, fieldName, handler, preline = false, disabled = false, blockCutCopyPaste = false, maxLength}) => {
    const handleNoCutCopyPaste = (event) => {
        if (blockCutCopyPaste) {
            event.preventDefault();
        }
        return;
    }

    const isNumericField = fieldName === "dnis" || fieldName === "ani";

    const handleNumericInput = (event) => {
        if (isNumericField && event.target.value && !/^\d+$/.test(event.target.value)) {
            event.preventDefault();
            return;
        }
        handler(event);
    };

    return (
        <Fragment>
            <Grid item xs={12} md={12}>
                <Typography component="label" variant='body2' sx={{whiteSpace: preline ? 'pre-line' : 'normal'}}> 
                        {title}
                </Typography>
                <TextField
                    id={ id }
                    variant="standard"                    
                    fullWidth                     
                    size="small"
                    value={ formFields[`${fieldName}`] }
                    error={ formFields[`${fieldName}Error`] }
                    helperText={ formFields[`${fieldName}Error`] ? formFields[`${fieldName}ErrorMsg`] : formFields[`${fieldName}HelpText`] }
                    onChange={isNumericField ? handleNumericInput : handler}
                    disabled={disabled}
                    autoComplete='off'
                    onCut={blockCutCopyPaste ? handleNoCutCopyPaste : handler}
                    onCopy={blockCutCopyPaste ? handleNoCutCopyPaste : handler}
                    onPaste={blockCutCopyPaste ? handleNoCutCopyPaste : handler}
                    inputProps={{ maxLength: maxLength }}
                />
            </Grid>
        </Fragment>
    )
};

export default CTextField;