import React, { useState, useEffect } from "react";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import "./RecordingPlaybackModal.css";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import constants from "../../../constants";
import backend from "../../../services/backend";
import Tooltip from "@mui/material/Tooltip";

const IpfxRecordingPlaybackModal = (props) => {
  const { isOpen, toggle, recording } = props;

  const canDownload = () => {
    const normalizedRole = sessionStorage
      .getItem(constants.R2S_USER_ROLE)
      .toLowerCase();
    return ["admin", "supervisorwithdownload", "ogc_admin"]
      .map((r) => r.toLowerCase())
      .includes(normalizedRole);
  };

  const handleDownload = async (
    recordingURL,
    filename = `${recording.ipfx_transaction_id}.mp3`
  ) => {
    if (!recordingURL) {
      console.error("Recording URL is not provided.");
      return;
    }

    // Calling the API to get the recording URL status
    try {
      let usertoken = sessionStorage.getItem(constants.GC_TOKEN);

      let apiResponse = await backend.getRecordingURLStatus(
        usertoken,
        recording.ipfx_transaction_id,
        true
      );
      // Handle the apiResponse here
      console.log(apiResponse); // or any other logic you need
    } catch (error) {
      console.error("Error fetching recording URL status:", error);
    }

    try {
      // Fetching the file
      const response = await fetch(recordingURL);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();

      // Creating a blob URL
      const blobUrl = window.URL.createObjectURL(blob);

      // Creating a temporary anchor element to download the blob
      const anchor = document.createElement("a");
      anchor.style.display = "none";
      anchor.href = blobUrl;
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();

      // Cleanup
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(anchor);

      console.log("Download initiated.");
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const modalBody = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
        <audio
          autoPlay={false}
          title={recording.ipfx_transaction_id}
          id={`audio_${recording.ipfx_transaction_id}`}
          controls
          src={recording.recording_file_path}
          className="custom-audio"
          controlsList="nodownload"
        />

        {canDownload() && (
          <Tooltip title="Download">
            <IconButton
              onClick={() => handleDownload(recording.recording_file_path)}
              size="large"
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };
  return (
    <Box>
      {recording && (
        <Dialog fullWidth maxWidth={"lg"} open={isOpen}>
          <DialogTitle
            sx={{
              pb: 0,
              backgroundColor: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ccc",
            }}
          >
            <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
              Transaction {recording.ipfx_transaction_id}
            </Typography>
            <IconButton
              aria-label="Close"
              onClick={() => {
                toggle();
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{ padding: "24px", backgroundColor: "#f7f7f7" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  gutterBottom
                >
                  Start Date
                </Typography>
                <Typography variant="body1">
                  {recording.conversation_start_ts &&
                    new Date(recording.conversation_start_ts).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  gutterBottom
                >
                  End Date
                </Typography>
                <Typography variant="body1">
                  {recording.conversation_end_ts &&
                    new Date(recording.conversation_end_ts).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  gutterBottom
                >
                  ANI
                </Typography>
                <Typography variant="body1">
                  {recording.caller_ani || "Anonymous"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  gutterBottom
                >
                  Direction
                </Typography>
                <Typography variant="body1">
                  {recording.direction || "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  gutterBottom
                >
                  Extension
                </Typography>
                <Typography variant="body1">
                  {recording.agent_extension || "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  gutterBottom
                >
                  Recording
                </Typography>
                <Box sx={{ my: 2 }}>{modalBody()}</Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default IpfxRecordingPlaybackModal;
