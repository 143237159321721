 
import { useGridRootProps } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import SyncIcon from '@mui/icons-material/Sync';
import {  GridFilterInputDate, GridFilterInputValue} from "@mui/x-data-grid"
import React from "react";
import { Box } from "@mui/material";
import { convertLegacyOperators } from '@mui/x-data-grid/colDef/utils';

const dateRegex = /(\d+)-(\d+)-(\d+)/;
const dateTimeRegex = /(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)/;

function buildApplyFilterFn(filterItem, compareFn, showTime, keepHours) {
  if (!filterItem.value) {
    return null;
  }
     
  const [year, month, day, hour, minute, seconds] = filterItem.value.match(showTime ? dateTimeRegex : dateRegex).slice(1).map(Number);
  const time = new Date(year, month - 1, day, hour || 0, minute || 0, seconds || 0).getTime();
  return value => {
    if (!value) {
      return false;
    }
    if (keepHours) {
      return compareFn(new Date(value).getTime(), time);
    }

    // Make a copy of the date to not reset the hours in the original object
    const dateCopy = new Date(value);
    const timeToCompare = dateCopy.setHours(showTime ? new Date(value).getHours() : 0, showTime ? new Date(value).getMinutes() : 0, showTime ? new Date(value).getSeconds() : 0, 0);
    return compareFn(timeToCompare, time);
  };
}
export const dateOperators = showTime => convertLegacyOperators([{
  value: 'is',
  getApplyFilterFnV7: filterItem => {
    return buildApplyFilterFn(filterItem, (value1, value2) => value1 === value2, showTime);
  },
  InputComponent: GridFilterInputDate,
  InputComponentProps: {
    type: showTime ? 'datetime-local' : 'date',
    InputProps: {
      inputProps: {
        step: "1"
      }
    }
  }
}, {
  value: 'not',
  getApplyFilterFnV7: filterItem => {
    return buildApplyFilterFn(filterItem, (value1, value2) => value1 !== value2, showTime);
  },
  InputComponent: GridFilterInputDate,
  InputComponentProps: {
    type: showTime ? 'datetime-local' : 'date',
    InputProps: {
      inputProps: {
        step: "1"
      }
    }
  }
}, {
  value: 'after',
  getApplyFilterFnV7: filterItem => {
    return buildApplyFilterFn(filterItem, (value1, value2) => value1 > value2, showTime);
  },
  InputComponent: GridFilterInputDate,
  InputComponentProps: {
    type: showTime ? 'datetime-local' : 'date',
    InputProps: {
      inputProps: {
        step: "1"
      }
    }
  }
},  {
  value: 'before',
  getApplyFilterFnV7: filterItem => {
    return buildApplyFilterFn(filterItem, (value1, value2) => value1 < value2, showTime, !showTime);
  },
  InputComponent: GridFilterInputDate,
  InputComponentProps: {
    type: showTime ? 'datetime-local' : 'date',
    InputProps: {
      inputProps: {
        step: "1"
      }
    }
  }
} 
/*{
  value: 'onOrAfter',
  getApplyFilterFnV7: filterItem => {
    return buildApplyFilterFn(filterItem, (value1, value2) => value1 >= value2, showTime);
  },
  InputComponent: GridFilterInputDate,
  InputComponentProps: {
    type: showTime ? 'datetime-local' : 'date'
  }
},
{
  value: 'onOrBefore',
  getApplyFilterFnV7: filterItem => {
    return buildApplyFilterFn(filterItem, (value1, value2) => value1 <= value2, showTime);
  },
  InputComponent: GridFilterInputDate,
  InputComponentProps: {
    type: showTime ? 'datetime-local' : 'date'
  }
}, {
  value: 'isEmpty',
  getApplyFilterFnV7: () => {
    return value => {
      return value == null;
    };
  },
  requiresFilterValue: false
}, {
  value: 'isNotEmpty',
  getApplyFilterFnV7: () => {
    return value => {
      return value != null;
    };
  },
  requiresFilterValue: false
}*/
]); 
export const notContainOperator = 
{
  value: "noContain",
  getApplyFilterFn: (
    filterItem,
    column
  ) => {
    if (
      !filterItem.field ||
      !filterItem.value ||
      !filterItem.operator
    ) {
      return null;
    }

    const filterRegex = new RegExp(filterItem.value, "i");
    return (params) => {
      const rowValue = column.valueGetter
        ? column.valueGetter(params)
        : params.value;
      return !filterRegex.test(rowValue?.toString() || "");
    };
  },
  InputComponent: GridFilterInputValue,
  
}

function InputNumberInterval(props) {
  const rootProps = useGridRootProps();
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = React.useRef();
  const [filterValueState, setFilterValueState] = React.useState(item.value ?? '');
  const [applying, setIsApplying] = React.useState(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound, upperBound) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [lowerBound, upperBound] });
    }, rootProps.filterDebounceMs);
  };

  const handleUpperFilterChange = (event) => {
    const newUpperBound = event.target.value;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (event) => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField
        name="lower-bound-input"
        placeholder="From"
        label="From"
        variant="standard"
        value={Number(filterValueState[0])}
        onChange={handleLowerFilterChange}
        type="number"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
      <TextField
        name="upper-bound-input"
        placeholder="To"
        label="To"
        variant="standard"
        value={Number(filterValueState[1])}
        onChange={handleUpperFilterChange}
        type="number"
        InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
      />
    </Box>
  );
}


export const rangeOperator = [
  {
    label: 'between',
    value: 'between',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      return ({ value }) => {
        return (
          value !== null &&
          Number(filterItem.value[0]) <= Number(value) &&
          Number(value) <= Number(filterItem.value[1])
        );
      };
    },
    InputComponent: InputNumberInterval,
  },
];