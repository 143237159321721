import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import constants from '../constants'

const PrivateRoute = ({ component: Component, ...rest }) => {
  sessionStorage.setItem("redirect_to", window.location.pathname);
  return (
    <Route {...rest} render={(props) => (sessionStorage.getItem(constants.GC_TOKEN)? <Component {...props} />: <Redirect to='/' /> )} />
  );
}

export default PrivateRoute;
