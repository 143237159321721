import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import utils from "../../services/utils";
import constants, { SEARCH_PARAM_DEPARTMENT } from "../../constants";
import "./ConversationsSearchCriteria.css";
import "./ConversationsSearchCriteria.css";
//import 'antd/dist/antd.css';
import { DatePicker } from "antd";
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Button,
  Box,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import CDateField from "../common/CDateField";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";

const { RangePicker } = DatePicker;

const IPFXConversationsSearchCriteria = (props) => {
  const { channels, role } = props;
  const [conversationID, setConversationID] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [startSearch, setStartSearch] = useState(false);
  const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date
  const [fields, setFields] = useState({ start: "", end: "" });
  const [searchDisabled, setSearchDisabled] = useState(true);

  function onSelectDate(dateArray, dateString) {
    console.log(`onSelectDate value is ${JSON.stringify(dateString)}`);
    setdateRange(dateString);
  }
  const handleChangeStartDate = (passedMoment) => {
    if (passedMoment && passedMoment.isValid()) {
      if (fields.start && typeof fields.start !== "string") {
        passedMoment.hour(fields.start.hour());
        passedMoment.minute(fields.start.minute());
      }

      // also set end time date
      let endMoment = moment(passedMoment);
      if (fields.end && typeof fields.end !== "string") {
        endMoment.hour(fields.end.hour());
        endMoment.minute(fields.end.minute());
        if (
          endMoment.hour() < passedMoment.hour() ||
          (endMoment.hour() == passedMoment.hour() &&
            endMoment.minute < passedMoment.minute())
        ) {
          endMoment.add(1, "day");
        }
      }

      setFields({
        ...fields,
        start: passedMoment,
        end: endMoment,
      });
      setSearchDisabled(false);
      setdateRange([
        passedMoment.format("YYYY-MM-DD"),
        endMoment.format("YYYY-MM-DD"),
      ]);
    } else {
      setSearchDisabled(true);
    }
    return;
  };
  const handleChangeEndDate = (passedMoment) => {
    if (passedMoment && passedMoment.isValid()) {
      /* if (fields.end && typeof (fields.end) !== 'string') {
                 passedMoment.month(fields.end.month());
                 passedMoment.date(fields.end.date());
                 passedMoment.year(fields.end.year());
             }*/

      let startMoment = fields.start;
      if (
        passedMoment.hour() < startMoment.hour() ||
        (passedMoment.hour() == startMoment.hour() &&
          passedMoment.minute < startMoment.minute())
      ) {
        // bump up end date if need be
        passedMoment.date(startMoment.date()).add(1, "day");
      }

      setFields({
        ...fields,
        end: passedMoment,
      });
      setSearchDisabled(false);
      setdateRange([
        startMoment.format("YYYY-MM-DD"),
        passedMoment.format("YYYY-MM-DD"),
      ]);
    } else {
      setSearchDisabled(true);
    }
    return;
  };
  // init from session storage
  useEffect(() => {
    try {
      sessionStorage.setItem(constants.R2S_CONVERSATIONS, "");
      const searchCriteriaTemp = JSON.parse(
        sessionStorage.getItem(constants.R2S_SEARCH_CRITERIA)
      );
      console.log(
        `ConversationsSearchCriteria:::: got search criteria as ${JSON.stringify(
          searchCriteriaTemp
        )}`
      );
      if (searchCriteriaTemp) {
        try {
          if (
            searchCriteriaTemp.startDateTime &&
            searchCriteriaTemp.endDateTime
          ) {
            let tempStartDate = new Date(searchCriteriaTemp.startDateTime);
            console.log(
              `Setting Start date time as ${JSON.stringify(
                searchCriteriaTemp.startDateTime
              )}`
            );
            console.log(`Setting Start date time as ${tempStartDate}`);
            console.log(`Setting Start date time as ${typeof tempStartDate}`);
            let tempEndDate = new Date(searchCriteriaTemp.endDateTime);
            console.log(
              `Setting Start date time as ${JSON.stringify(
                searchCriteriaTemp.endDateTime
              )}`
            );
            console.log(`Setting Start date time as ${tempEndDate}`);
            console.log(`Setting Start date time as ${typeof tempEndDate}`);
            setdateRange(tempStartDate, tempEndDate);
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (!searchCriteriaTemp) return;
    } catch (error) {
      console.log("conversationsSearchCriteria.useeffect.error", error);
    }
  }, []);

  const search = async () => {
    setStartSearch(true);
    if (!dateRange[0] || !dateRange[1]) return;
    console.log("Searching...");
    const searchCriteria = new Map();
    if (dateRange[0]) {
      let tempStartDate = moment(dateRange[0], "YYYY-MM-DD").set({
        hour: 0,
        minute: 0,
      });
      searchCriteria.set(
        constants.SEARCH_PARAM_STARTDT,
        tempStartDate.toISOString()
      );
    }

    if (dateRange[1]) {
      let tempEndDate = moment(dateRange[1], "YYYY-MM-DD").set({
        hour: 23,
        minute: 59,
      });
      searchCriteria.set(
        constants.SEARCH_PARAM_ENDDT,
        tempEndDate.toISOString()
      );
    }
    props.search(utils.mapToObj(searchCriteria));
    sessionStorage.setItem(
      constants.R2S_SEARCH_CRITERIA,
      JSON.stringify(utils.mapToObj(searchCriteria))
    );
  };
  const cancel = async () => {
    console.log("canceling...");
    props.cancel();
  };

  const getDatePickerClassName = () => {
    return !startSearch || (startDate && endDate)
      ? "input-group d-inline-flex w-auto"
      : "input-group d-inline-flex w-auto date-picker-error";
  };

  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={search}
        sx={{ padding: "16px", backgroundColor: "#f5f5f5" }}
      >
        <Grid container spacing={2} alignItems="center">
          {searchDisabled && (
            <Grid item xs={12}>
              <Box
                borderLeft={4}
                borderColor="warning.main"
                bgcolor="background.paper"
                p={0.1}
                my={0.1}
              >
                <Typography variant="body1" color="textPrimary">
                  <strong>&nbsp;&nbsp;Attention: </strong>Ensure that you have
                  entered both the start and end dates.
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              component="label"
              htmlFor="start-date"
              variant="subtitle2"
              color="textSecondary"
              sx={{ display: "block", marginBottom: "8px" }}
            >
              Start Date
            </Typography>
            <CDateField
              formFields={fields}
              fieldName={"start"}
              handler={handleChangeStartDate}
              id="start-date"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              component="label"
              htmlFor="end-date"
              variant="subtitle2"
              color="textSecondary"
              sx={{ display: "block", marginBottom: "8px" }}
            >
              End Date
            </Typography>
            <CDateField
              formFields={fields}
              fieldName={"end"}
              handler={handleChangeEndDate}
              id="end-date"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              size="medium"
              variant="contained"
              color="primary"
              disabled={searchDisabled}
              onClick={search}
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default IPFXConversationsSearchCriteria;
