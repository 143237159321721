// React
import React, { useState } from 'react'
// Third Party
import {
    Dialog, DialogTitle, DialogContent, Box, Alert, AlertTitle, IconButton
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import RecordingForm from './RecordingForm';
import { CopyAllRounded } from '@mui/icons-material';
import AlertMessage from 'components/alertMessage/AlertMessage';
import ShareIcon from '@mui/icons-material/Share';
import { CopyToClipboard } from "react-copy-to-clipboard";
import constants from "../../constants";

const RecordingDialog = ({
    dialogModal, dialogSetter,
    dialogFields, dialogFieldsSetter, onDialogResponse
}) => {

    const isMounted = React.useRef(true);
    const [isCopied, setIsCopied] = React.useState(false);
    React.useEffect(() => {
        return () => { isMounted.current = false; }
    }, []);


    const [error, setError] = React.useState(null);
    const [searchAlertVisible, setSearchAlertVisible] = useState(false)
    const [searchAlertMessage, setSearchAlertMessage] = useState()

    const onRecordingModalDismiss = (event, reason) => {
        if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
            // prevents closure 
            return;
        }

        if (reason && reason === 'cancel') {
            dialogSetter(false);
            onDialogResponse('Cancel');
            return;
        }
    };
    const onClose = (event) => {
        dialogSetter(false);
        return;
    };

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
        setSearchAlertVisible(true)
        setSearchAlertMessage(`Copied to clipboard.`)
    };
    return (
        <React.Fragment>
            <Dialog fullWidth maxWidth={'xl'} open={dialogModal} onClose={onRecordingModalDismiss}>
                <DialogTitle sx={{ pb: 0, backgroundColor: 'white', fontWeight: 600 }}>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>Contact {dialogFields.conversation_id}
                            <IconButton>
                                <CopyToClipboard
                                    text={dialogFields.conversation_id}
                                    onCopy={onCopyText}
                                >
                                    <span><CopyAllRounded /></span>
                                </CopyToClipboard>
                            </IconButton>
                            <IconButton>
                                <CopyToClipboard
                                    text={`${window.location.href}/${dialogFields.conversation_id}/details?environment=${sessionStorage.getItem(constants.GC_ENVIRONMENT)}`}
                                    onCopy={onCopyText}
                                >
                                    <span><ShareIcon /></span>
                                </CopyToClipboard>
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers sx={{ backgroundColor: 'white' }}>
                    <AlertMessage
                        message={searchAlertMessage}
                        visible={searchAlertVisible}
                        setVisible={x => { setSearchAlertVisible(x) }}
                    />
                    <RecordingForm
                        fields={dialogFields}
                        setFields={dialogFieldsSetter}
                        onCloseDialog={onRecordingModalDismiss}
                    />

                    {/*<textarea name="textarea" rows="4" cols="1"  id="contact_id">{dialogFields.conversation_id}</textarea>*/}


                </DialogContent>
            </Dialog>

        </React.Fragment>
    );
};

export default RecordingDialog;