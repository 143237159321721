import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import backend from "../../services/backend";
import WaitingModal from "../Modals/waitingModal/WaitingModal";
import AlertModal from "../Modals/alertModal/AlertModal";
import constants from "../../constants";

import "./ConversationDetails.css";
import RecordingForm from "./RecordingForm";
import { Box, Typography, Divider } from "@mui/material";

const ConversationDetails = (props) => {
  const [error, setError] = useState();
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
  const [recordings, setRecordings] = useState([]);
  const [conversation, setConversation] = useState();
  const [isRecordingPlaybackModalOpen, setIsRecordingPlaybackModalOpen] =
    useState(false);
  const [recordingPlayback, setRecordingPlayback] = useState();
  const [role, setRole] = useState();
  const history = useHistory();
  const { id } = useParams();
  const isMounted = React.useRef(true);
  const [isStillProcessing, setIsStillProcessing] = useState(true);

  useEffect(() => {
    // init load
    setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
    console.log("Conversation Details role : " + role);
    const fetchConversation = async () => {
      let convResponse;
      try {
        if (!role) {
          const usersMe = await backend.getUsersMe(
            sessionStorage.getItem(constants.GC_TOKEN)
          );
          const isUser = usersMe.user.role.value;

          setRole(isUser);
          sessionStorage.setItem(constants.R2S_USER_ROLE, isUser);
        }

        setIsWaitingModalOpen(true);
        convResponse = await backend.getConversation(
          sessionStorage.getItem(constants.GC_TOKEN),
          id
        );
        console.log("getConversation.response:", convResponse);
        setConversation(convResponse);
        setRecordings([convResponse.recording]);
        // if (convResponse.recording) {
        //     convMetaDataresponse = await backend.getConversationMetadata(sessionStorage.getItem(constants.GC_TOKEN), id, convResponse.recording)
        //     let recordingList = [];
        //     recordingList.push(convMetaDataresponse.recording)
        //     setRecordings(recordingList)
        // }else{
        //     console.log('convResponse.recording is undefined');
        //
      } catch (error) {
        console.log("fetchConversation:,", error);
        setError(
          `An error occured while fetching the conversation:${JSON.stringify(
            error.message
          )}`
        );
      } finally {
        setIsWaitingModalOpen(false);
      }

    };
    fetchConversation();
  }, [id]);


  const toggleRecordingPlaybackModal = () =>
    setIsRecordingPlaybackModalOpen(!isRecordingPlaybackModalOpen);

  return (
    <React.Fragment>
      <Header role={role} />
      <WaitingModal
        isOpen={isWaitingModalOpen}
        header="Loading conversation details"
      />
      <AlertModal
        isOpen={!!error}
        header="Error"
        toggle={() => {
          setError(undefined);
        }}
        body={error}
      />

      <Box sx={{ mx: 4, my: 10 }}>
        {conversation && <RecordingForm fields={conversation} />}
      </Box>
      <Footer />
    </React.Fragment>
  );
};

const sleepFor = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export default ConversationDetails;
